import React from 'react';
import PropTypes from 'prop-types';
import {css} from '@emotion/react';

const Video = ({src, className, showControls}) => {
	const controls = showControls ? {controls: true, controlslist: 'nofullscreen nodownload noremoteplayback noplaybackrate', disablePictureInPicture: true} : {};
	return (
		<video
			className={className}
			playsInline
			autoPlay
			muted
			loop
			{...controls}
			css={css`
				min-width: 20px;
			`}
		>
			<source src={src ?? ''} />
		</video>
	);
};

Video.propTypes = {
	src: PropTypes.string,
	className: PropTypes.node,
	showControls: PropTypes.bool,
};

export default Video;
